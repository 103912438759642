import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "general",
  initialState: {
    surveys: null,
  },
  reducers: {
    setSurveysRedux(state, { payload }) {
      state.surveys = payload;
    },
  },
});

export const { setSurveysRedux } = slice.actions;

export const surveysRedux = (state) => state.general.surveys;

export default slice.reducer;
