import { configureStore } from "@reduxjs/toolkit";

import app from "./application/app.slice";
import bases from "./bases/bases.slice";
import general from "./general/general.slice";

export default configureStore({
  reducer: {
    app,
    general,
    bases,
  },
});
