import React from "react";
import { Tabs } from "antd";
import { useSelector } from "react-redux";

//redux
import { windowHWRedux } from "../../../redux/application/app.slice";

const TabNavigation = ({ children }) => {
  const dimentionsScreen = useSelector(windowHWRedux);

  const changeTabPosition = (tabId) => {
    window.location.href = tabId === "historic" ? "/bases" : "/send-bases";
    return;
  };

  return (
    <Tabs
      tabPosition={dimentionsScreen[0] >= 768 ? "left" : "top"}
      activeKey={
        window.location.href.indexOf("/send-bases") >= 0
          ? "send-base"
          : "historic"
      }
      onTabClick={(tabId) => changeTabPosition(tabId)}
      items={[
        {
          label: `Histórico`,
          key: "historic",
          children,
        },
        {
          label: `Enviar Base`,
          key: "send-base",
          children,
        },
      ]}
    />
  );
};

export default TabNavigation;
