//import api, { headers } from "../../services/api";

export const doLoginAPI = async (data) => {
  //return api.post("login", data, { headers });

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  return fetch("https://account2.inovyo.com/api/login", {
    method: "POST",
    headers: myHeaders,
    mode: "cors",
    credentials: "include",
    body: JSON.stringify(data),
  }).then((res) => res.json());
};
