import { toast } from "react-toastify";

//redux
import { fetchHubAPI, qlikConnectionAPI } from "./general.service";
import { setSurveysRedux } from "./general.slice";
import { setLoadingRedux } from "../application/app.slice";

export const fetchHub = () => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));

    qlikConnectionAPI();

    fetchHubAPI()
      .then(({ data }) => {
        dispatch(setSurveysRedux(data));
      })
      .catch(() => {
        toast.error("Falha ao carregar pesquisa!");
      })
      .finally(() => {
        dispatch(setLoadingRedux(false));
      });
  };
};
