import { useEffect } from "react";
import { Card, Space, Select, Input, Divider } from "antd";
import { useDispatch, useSelector } from "react-redux";

//redux
import { fetchHub } from "../../redux/general/general.actions";
import { surveysRedux } from "../../redux/general/general.slice";

//styles
import "../../assets/styles/general/home.css";

//images
import axIcon from "../../assets/images/circle-ax.svg";
import bxIcon from "../../assets/images/circle-bx.svg";
import cxIcon from "../../assets/images/circle-cx.svg";
import cxcIcon from "../../assets/images/circle-cxc.svg";
import exIcon from "../../assets/images/circle-ex.svg";
import slxIcon from "../../assets/images/circle-slx.svg";
import ahIcon from "../../assets/images/circle-ah.svg";

const HomePage = () => {
  const dispatch = useDispatch();
  const surveys = useSelector(surveysRedux);

  const icons = {
    ax: { icon: axIcon, label: "After Experience" },
    bx: { icon: bxIcon, label: "Before Experience" },
    ex: { icon: exIcon, label: "Employee Experience" },
    cx: { icon: cxIcon, label: "Customer Experience" },
    cxc: { icon: cxcIcon, label: "Customer Experience Consulting" },
    slx: { icon: slxIcon, label: "Social Listening Experience" },
    ah: { icon: ahIcon, label: "Adhoc" },
  };

  useEffect(() => {
    dispatch(fetchHub());
    // eslint-disable-next-line
  }, []);

  return (
    <div id="home-page">
      {surveys !== null && (
        <Card>
          <div className="inline-filters">
            <div className="options">
              <Space.Compact direction="vertical" style={{ width: "100%" }}>
                <label htmlFor="">Buscar</label>
                <Input onChange={() => {}} />
              </Space.Compact>
            </div>
            <div className="options">
              <Space.Compact direction="vertical" style={{ width: "100%" }}>
                <label htmlFor="">Categoria</label>
                <Select
                  onChange={() => {}}
                  options={[
                    {
                      value: "jack",
                      label: "Jack",
                    },
                    {
                      value: "lucy",
                      label: "Lucy",
                    },
                    {
                      value: "Yiminghe",
                      label: "yiminghe",
                    },
                  ]}
                />
              </Space.Compact>
            </div>
            <div className="options">
              <Space.Compact direction="vertical" style={{ width: "100%" }}>
                <label htmlFor="">Ordenar por</label>
                <Select
                  onChange={() => {}}
                  options={[
                    {
                      value: "jack",
                      label: "Jack",
                    },
                    {
                      value: "lucy",
                      label: "Lucy",
                    },
                    {
                      value: "Yiminghe",
                      label: "yiminghe",
                    },
                  ]}
                />
              </Space.Compact>
            </div>
          </div>
          <Divider />
          <div className="content">
            {surveys.map((dash, i) => {
              return (
                <Card.Grid
                  key={`dash-item-${i}`}
                  className="item"
                  style={{ padding: "10px" }}
                >
                  <a href={dash.url}>
                    <div className="icon">
                      <img src={icons[dash.type].icon} alt="Icon" />
                    </div>
                    <div className="description">
                      <div className="dash-name">{dash.label}</div>
                      <div className="dash-type">{icons[dash.type].label}</div>
                    </div>
                  </a>
                </Card.Grid>
              );
            })}
          </div>
        </Card>
      )}
    </div>
  );
};

export default HomePage;
