import React, { useContext, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

//providers
import { Context } from "../providers/AuthContext";

//compoenents
import LayoutApp from "../components/app/LayoutApp";

//redux
import { setWindowHWRedux } from "../redux/application/app.slice";

//routes
import { listRoutes, activeRoute } from "./modules";

const AppRoutes = () => {
  const dispatch = useDispatch();
  let match = window.location.pathname;
  const { loading, authenticated } = useContext(Context);
  const allRoutes = listRoutes();
  const { auth } = activeRoute();

  const calcResize = () => {
    let funcSetResize = null;
    window.addEventListener("resize", () => {
      clearTimeout(funcSetResize);
      funcSetResize = setTimeout(() => {
        dispatch(setWindowHWRedux());
      }, 300);
    });
  };

  useEffect(() => {
    if (!window.navigator.onLine) {
      toast.error("Você está sem internet!");
    }
    window.addEventListener("online", () => {
      toast.success("Você está com internet!");
    });
    window.addEventListener("offline", () => {
      toast.error("Você está sem internet!");
    });

    calcResize();
    return calcResize();
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Carregando...
      </div>
    );
  } else {
    /* Se auth existir vindo do componente de rota,
      significa que é uma rota privada e deve validar todos
      os acessos antes do usuário visualizar */
    if (auth) {
      /* Só tem acesso as rotas privadas se autenticado */
      if (authenticated) {
        /* Quando autenticado e estiver na rota /login
         * será redirecionado para /home */
        if (match === "/login") {
          window.location.href = "/";
          return;
        }
      } else {
        window.location.href = "/login";
        return;
      }
    }
  }

  return (
    <LayoutApp>
      <Routes>
        {allRoutes.map((e, index) => (
          <Route path={e.path} key={"routes" + index} element={<e.page />} />
        ))}
      </Routes>
    </LayoutApp>
  );
};

export default AppRoutes;
