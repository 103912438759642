import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "bases",
  initialState: {
    bases: null,
    tbLoading: false, //loading das tabelas
  },
  reducers: {
    setTbLoadingRedux(state, { payload }) {
      state.tbLoading = payload;
    },
    setBasesRedux(state, { payload }) {
      state.bases = payload;
    },
  },
});

export const { setTbLoadingRedux, setBasesRedux } = slice.actions;

export const tbLoadingRedux = (state) => state.bases.tbLoading;
export const basesRedux = (state) => state.bases.bases;

export default slice.reducer;
