import React, { useState, useEffect } from "react";
import { Card, Typography, Table, Tag, Popover, Menu } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  DeleteFilled,
  DownloadOutlined,
  MoreOutlined,
} from "@ant-design/icons";

//component
import TabNavigation from "./components/TabNavigation";

//redux
import { tbLoadingRedux, basesRedux } from "../../redux/bases/bases.slice";
import { fetchBases } from "../../redux/bases/bases.actions";

const HistoricBasePage = () => {
  const { Title } = Typography;
  const dispatch = useDispatch();

  //states
  const [resultsOnPage, setResultsOnPage] = useState(10);

  //redux state
  const tableLoading = useSelector(tbLoadingRedux);
  const bases = useSelector(basesRedux);

  useEffect(() => {
    dispatch(fetchBases());
    // eslint-disable-next-line
  }, []);

  const tableChanges = (e) => {
    if (e.pageSize !== resultsOnPage) {
      setResultsOnPage(e.pageSize);
    }
  };

  const deleteFile = () => {};
  const downloadFile = () => {};

  return (
    <Card>
      <TabNavigation>
        <div id="historic-base-page">
          <Title level={4}>Bases</Title>
          <Table
            responsive={true}
            columns={[
              {
                title: "Data de Envio",
                dataIndex: "createdat",
                sorter: (a, b) => a.timestamp - b.timestamp,
                defaultSortOrder: "descend",
                width: 130,
              },
              {
                title: "Ponto de Contato",
                dataIndex: "survey",
                sorter: (a, b) => {
                  return a.survey < b.survey ? -1 : a.survey > b.survey ? 1 : 0;
                },
                width: 150,
              },
              {
                title: "Arquivo",
                dataIndex: "filename",
                sorter: (a, b) => {
                  return a.filename < b.filename
                    ? -1
                    : a.filename > b.filename
                    ? 1
                    : 0;
                },
                width: 150,
              },
              {
                title: "Qtd. de Contatos",
                dataIndex: "contacts",
                sorter: (a, b) => a.contacts - b.contacts,
                width: 100,
              },
              {
                title: "Status",
                dataIndex: "status",
                render: (item) => {
                  let color =
                    item === "waiting"
                      ? "#999999"
                      : item === "processing"
                      ? "#ACE8FF"
                      : item === "success"
                      ? "#8FCA8F"
                      : "#D1807D";

                  return (
                    <Tag color={color} key={item}>
                      {item === "waiting"
                        ? "Na fila"
                        : item === "processing"
                        ? "Processando"
                        : item === "success"
                        ? "Sucesso"
                        : "Falha"}
                    </Tag>
                  );
                },
                sorter: (a, b) => a.status - b.status,
                width: 100,
                key: 5,
              },
              {
                title: "Ações",
                fixed: "right",
                width: 70,
                align: "right",
                render: (row) => {
                  return (
                    <>
                      <Popover
                        content={
                          <Menu
                            onClick={({ key }) => {
                              if (key === "download-base") {
                                downloadFile();
                              } else if (key === "delete-base") {
                                deleteFile();
                              }
                            }}
                            mode="inline"
                            items={[
                              {
                                label: "Download da base",
                                key: "download-base",
                                icon: <DownloadOutlined />,
                              },
                              {
                                label: "Excluir Base",
                                key: "delete-base",
                                icon: <DeleteFilled />,
                              },
                            ]}
                          />
                        }
                        trigger="click"
                        placement="left"
                      >
                        <MoreOutlined style={{ fontSize: 20 }} />
                      </Popover>
                    </>
                  );
                },
              },
            ]}
            dataSource={bases}
            pagination={{
              pageSize: resultsOnPage,
              showSizeChanger: true,
              size: "small",
            }}
            onChange={(e) => tableChanges(e)}
            scroll={{ y: 380 }}
            loading={tableLoading}
          />
        </div>
      </TabNavigation>
    </Card>
  );
};

export default HistoricBasePage;
