import React from "react";
import { Card } from "antd";

//component
import TabNavigation from "./components/TabNavigation";

const SendBasePage = () => {
  return (
    <Card>
      <TabNavigation>
        <div id="send-base-page">enviar base</div>
      </TabNavigation>
    </Card>
  );
};

export default SendBasePage;
