import moment from "moment";

//redux
import { setBasesRedux, setTbLoadingRedux } from "./bases.slice";

export const fetchBases = () => {
  return (dispatch) => {
    dispatch(setTbLoadingRedux(true));
    /*
   {
      user: 1,
      map: {
        header: true,
      },
      modifiedat: "2021-03-22T16:03:03",
      contacts: "77",
      filename: "Pesquisa de Plantão - 15.03 - 21.03.xlsx",
      id: 179,
      status: "success",
      bounce: null,
      surveyid: 278,
      createdat: moment("2021-03-22T14:50:58").format('DD/MM/YY HH:mm'), //createdat: "2021-03-22T14:50:58",
      timestamp: moment("2021-03-22T14:50:58").format('x'),
    },
    {
      user: 3,
      map: {
        name: "Nome",
        email: "Email",
        phone: "Telefone",
        header: true,
      },
      modifiedat: "2021-03-31T14:53:10",
      contacts: "2",
      filename: "teste-ftp.xlsx",
      id: 202,
      status: "success",
      bounce: null,
      surveyid: 351,
      createdat: moment("2021-03-22T14:50:58").format('DD/MM/YY HH:mm'),
      timestamp: moment("2021-03-22T14:50:58").format('x'),
    },
  */

    const data = [
      {
        id: 5,
        key: 5,
        createdat: moment("2021-03-22T14:50:58").format("DD/MM/YY HH:mm"), //createdat: "2021-03-22T14:50:58",
        timestamp: moment("2021-03-22T14:50:58").format("x"),
        survey: "Precisa mudar SQL para left JOIN",
        contacts: 239,
        status: "success",
        filename: "teste-ftp.xlsx",
      },
      {
        id: 6,
        key: 6,
        createdat: moment("2022-03-03T16:28:19").format("DD/MM/YY HH:mm"),
        timestamp: moment("2022-03-03T16:28:19").format("x"),
        survey: "Precisa mudar SQL para left JOIN",
        contacts: 36,
        status: "success",
        filename: "Pesquisa de Plantão - 15.03 - 21.03.xlsx",
      },
    ];

    setTimeout(() => {
      dispatch(setBasesRedux(data));
      dispatch(setTbLoadingRedux(false));
    }, 1000);
  };
};
