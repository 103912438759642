import React, { useContext } from "react";
import { Menu } from "antd";
import { SettingOutlined } from "@ant-design/icons";

//styles
import "../../assets/styles/components/app/header-app.css";

//images
import LogoBrain from "../../assets/images/brain-inovyo.svg";

//provider
import { Context } from "../../providers/AuthContext";

const HeaderApp = () => {
  const urlFull = window.location.href;
  const { authenticated, token } = useContext(Context);

  const menuLinks = [
    { url: "", label: "Dashboards" },
    { url: "bases", label: "Bases", keys: ["bases", "send-bases"] },
    { url: "users", label: "Usuários" },
    { url: "surveys", label: "Pesquisas" },
    { url: "qmc", label: "QMC" },
  ];

  return (
    <>
      {authenticated && (
        <header id="header-inovyo">
          <div className="logo">
            <a href="/" title="Home">
              <img src={LogoBrain} alt="LogoBrain" />
            </a>
          </div>
          <div className="menu-small">
            {menuLinks.map((item, i) => {
              if (
                token.role === "demo" &&
                ["bases", "surveys"].includes(item.url)
              ) {
                return <></>;
              }

              if (token.role !== "admin" && item.url === "qmc") {
                return <></>;
              }

              if (token.role === "ftp" && item.url === "") {
                return <></>;
              }

              return (
                <a
                  href={`/${item.url}`}
                  key={`menu-link-${i}`}
                  className={
                    urlFull.split("/")[3] === item.url ||
                    (item?.keys && item.keys.includes(urlFull.split("/")[3]))
                      ? "selected"
                      : ""
                  }
                >
                  {item.label}
                </a>
              );
            })}
          </div>
          <div className="options">
            <Menu
              onClick={({ key }) => {
                window.location.href = "/" + key;
                return;
              }}
              style={{ height: "60px" }}
              mode="horizontal"
              items={[
                {
                  label: "Conta",
                  key: "SubMenu",
                  icon: <SettingOutlined />,
                  children: [
                    {
                      label: "Configurações",
                      key: "settings",
                    },
                    {
                      label: "Sair",
                      key: "logout",
                    },
                  ],
                },
              ]}
            />
          </div>
        </header>
      )}
    </>
  );
};

export default HeaderApp;
